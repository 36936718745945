function gaTrack () {
    let index
    let trackLink = document.getElementsByClassName('js-track')
    for (index = 0; index < trackLink.length; ++index) {
      let elem = trackLink[index]
      elem.onclick = function () {
        gtag(
          'event',
          this.getAttribute('data-action'),
          {
            'event_category': this.getAttribute('data-category'),
            'event_label': this.getAttribute('data-label')
          }
        )
      }
    }
  }
  
  gaTrack()  